$mainColor: #000 !important;
$interFont: 'Nunito Sans', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap');
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


body {
    font-family: $interFont  !important;
    overflow-x: hidden;
}

ul {
    padding: 0;
    margin: 0 !important;
    list-style: none;
}

a:hover {
    text-decoration: none !important;
}

// .container {
//     max-width: 85% !important;
// }


//=====================================NAV CSS START HERE======================================


.custom-navbar {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0;
    z-index: 99;
    border-radius: 0px 0px 10px 10px;


    .container-fluid {
        padding: 0;
    }

    .navbar {
        background-color: transparent;
        box-shadow: none;
        padding-left: 0;
        padding-right: 0;
        padding: 15px 0px;
        transition: 0.5s all ease;
    }

    .navbar-brand img {
        width: 207px;
        transition: 0.5s all ease;
    }

    .nav-item {
        margin-right: 30px;

        a {
            color: #040222;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.61;
            letter-spacing: normal;
            text-align: right;
            color: #fff;
        }
    }

    .dropdown.nav-item {
        position: relative;
    }

    .dropdown.nav-item:before {
        content: '';
        background-color: #1717b4;
        width: 6px;
        height: 6px;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 50px;
    }

    .let-start {
        background-color: #189ad3;
        padding: 4.1px 25px !important;
        border-radius: 23px;
        color: #fff !important;
        font-size: 16px !important;

        img {
            margin-right: 10px;
        }
    }

    .navbar-nav {
        align-items: center;
    }

    .nav-item:last-child {
        margin-right: 0;
    }
}

// =====================================END HERE======================================

// =============================FOOTER===================================

.footer-wrapper {
    background-color: #e2f7ff;
    padding: 50px 0px 25px;
    border-radius: 60px 60px 0px 0px;

    .footer-logo-wrap {
        margin-bottom: 25px;
    }

    .top-footer-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 42px;
    }

    .inner-footer {
        display: block;
        text-align: left;
    }

    .footer-img {
        width: 200px;
    }

    .lets-chat {
        width: 237px;
        height: 61px;
        border-radius: 50px;
        background-color: #f11a10;
        font-weight: 600;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        font-size: 18px;
        border: 0;
        margin-top: 20px;
    }

    ul.social-icon {
        margin: 0;

        li {
            display: inline-block;
            margin-right: 50px;

            a {
                color: #189ad3;
            }
        }

        li:last-child {
            margin-right: 0;
        }
    }

    .footer-wrap {


        h4 {
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #040222;
            margin-bottom: 15px;
        }

        h3 {
            font-size: 40px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.3;
            letter-spacing: normal;
            text-align: left;
            color: #000;
        }

        .sub-footer-menu {
            a {
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: left;
                color: #040222;

            }

            li {
                margin-bottom: 10px;
            }
        }
    }

    .copywrite-wrap {
        margin-top: 70px;

        p {
            margin-bottom: 0;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #f11a10;
        }

        .divide-nation {
            margin: 0px 8px;
        }

        .in-country {
            display: inline-block;
            margin-right: 25px;
        }

        .scroll-top {
            background-color: transparent;
            border: 0;
            padding: 0;

            img {
                width: 24px;
            }
        }
    }

    .hr-footer {
        border-top: 1px solid #6ecef1;
    }

    .get-in-touch {
        font-size: 42px;
        font-weight: bold;
        text-align: left;
        color: #000;
    }

    .ft-wrapper {

        h5 {
            font-size: 24px;
            font-weight: bold;
            letter-spacing: normal;
            text-align: left;
            color: #000;
            margin-top: 12px;
            margin-bottom: 30px;
        }

        li,
        a {
            font-size: 18px;
            font-weight: normal;
            letter-spacing: normal;
            text-align: left;
            color: #000;
            display: flex;
        }

        .font-icon {
            color: #189ad3;
            display: flex;
            width: 25px;
            position: relative;
            top: 5px;
        }

        .ul-left,
        .ul-right {
            display: inline-block;
            vertical-align: top;

            li {
                margin-bottom: 30px;

                a {
                    font-size: 18px;
                    font-weight: normal;
                    text-align: left;
                    color: #232830;
                    position: relative;
                }

                a:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0%;
                    height: 1px;
                    background-color: #000;
                    transition: 0.5s all ease;

                }

                a:hover {
                    color: #000;
                }

                a:hover:before {
                    width: 100%;
                    transition: 0.5s all ease;
                }
            }
        }

        .ul-left {
            margin-right: 60px !important;
        }
    }

    .footer-menu {
        position: relative;

        .orange-line-wrap {
            width: 250px;

            img {
                width: 100%;
            }
        }
    }

    .reach-us {

        li {
            margin-bottom: 30px;

            .content-text {
                width: 100%;
            }
        }

    }

}

.copywrite-wrap {
    background-color: #fff;
    padding: 10px 0px;

    p {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: normal;
        color: #232830;
        display: inline-block;
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            margin-right: 25px;
            position: relative;

            a {
                font-size: 14px;
                font-weight: normal;
                text-align: center;
                color: #232830;
            }


        }

        li:before {
            content: '';
            background-color: #000;
            width: 1px;
            height: 15px;
            position: absolute;
            right: -13px;
            top: 50%;
            transform: translateY(-50%);

        }

        li:last-child {
            margin-right: 0;
        }

        li:last-child:before {
            display: none;
        }
    }

}

.scroll-top {
    background-color: #335291;
    border: 0;
    width: 65px;
    height: 65px;
    border-radius: 50px;
    color: #fff;
    font-size: 30px;
    line-height: 65px;
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;

}

// =================================END HERE==============================



// ===================================MAIN BANNER===============================


.main-banner {
    position: relative;


    .wrapper-banner-slider {
        .container {
            position: relative;
        }
    }

    .wrapper-banner-slider:before {
        content: '';
        background: #00fcff;
        background: linear-gradient(0deg, rgba(0, 252, 255, 0) 0%, rgba(0, 0, 0, 0.9) 81%);
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        height: 100px;
        z-index: 9;
    }

    .banner-content {
        position: absolute;
        top: 150px;
        left: 0;
        z-index: 1;
        width: 65%;
        text-align: left;
        padding: 0px 15px;

        h1 {
            font-size: 66px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.18;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
        }

        p {
            font-size: 20px;
            font-weight: normal;
            text-align: left;
            color: #fff;
        }

        .orange-line-wrap {
            margin-bottom: 30px;
        }

        .input-setup {
            width: 550px;
            height: 70px;
            position: relative;

            .form-control {
                height: 70px;
                border-radius: 50px;
                border: 0;
                font-size: 22px;
                text-align: left;
                color: #000;
                padding-right: 200px;
                padding-left: 25px;
            }

            .form-control::placeholder {
                color: #969696;
            }

            .get-email-start {
                position: absolute;
                top: 50%;
                right: 8px;
                height: 78%;
                width: 200px;
                background-color: #335291;
                border: 0;
                border-radius: 50px;
                transform: translateY(-50%);
                font-size: 20px;
                font-weight: 600;
                color: #fff;

                .glow-icon {
                    display: inline-block;
                    width: 40px;
                }

            }
        }

        .subscribe-input {
            margin-top: 30px;
        }

    }
}

// .main-banner:before{
//     content: '';
//     background: rgb(255,255,255);
//     background: linear-gradient(0deg, rgba(255,255,255,0.23853291316526615) 0%, rgba(0,0,0,1) 100%);
//     position: absolute;
//     top: 0;
//     width: 100%;
//     height: 60px;
//     left: 0;
// }


.form-control:focus {
    box-shadow: none !important;
    outline: none !important;
}

// ===============================END HERE============================


// ===============================FAQ==============================

.faq-wrapper {
    padding: 100px 0px;

    .faq-note {
        width: 85%;
        margin: 0px auto;
        margin-top: 30px;

        h5 {
            margin-bottom: 0;
            font-size: 26px;
            font-weight: 600;
            text-align: left;
            color: #000;
        }

        .card {
            border: 1px solid #eeeeee;
            margin-bottom: 20px;
            position: relative;
            padding: 23px 23px;
            cursor: pointer;
            border-radius: 10px;

            .faq-bullet {
                color: #6ecef1;
                font-size: 22px;
                margin-right: 20px;
                position: relative;
                top: 2px;
            }

            .acc-icon {
                position: absolute;
                top: 25px;
                right: 23px;

                .fa-plus {
                    font-weight: 900;
                    color: #000
                }
            }

            .card-title {
                margin-bottom: 0;
            }

            .home_cardBody {
                text-align: left;
                padding: 15px 10px 15px 45px;

                p {
                    font-size: 20px;
                    font-weight: normal;
                    text-align: left;
                    color: #232830;
                    margin-bottom: 0;
                }
            }

            .fa-minus {
                color: #189ad3;
            }
        }
    }


}

.sub-head {
    width: 250px;
    margin: 0px auto;
}

.sub-heading {
    font-size: 66px;
    font-weight: 800;
    text-align: center;
    color: #000;
}


.fixed-nav {
    background: rgba(0, 73, 96, 0.9);
    position: relative;
}

.fixed-nav .navbar {
    padding: 5px 0px;
    transition: 0.5s all ease;
}

.fixed-nav .navbar-brand img {
    width: 150px !important;
    transition: 0.5s all ease;
}


// ===============================END HERE========================


// =================================Challenge=============================

.challenge-wrap {
    position: relative;
    margin-top: -100px;

    .box-wrap {
        border-radius: 12px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12);
        background-color: #fff;
        padding: 50px 70px;
        text-align: left;

        h2 {
            font-size: 42px;
            font-weight: bold;
            text-align: left;
            color: #000;
        }

        .para-p {
            margin-top: 5px;
        }

        p {
            font-size: 18px;
            font-weight: normal;
            text-align: left;
            color: #232830;
            margin-bottom: 0;
        }

        h4 {
            font-size: 50px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.92;
            letter-spacing: normal;
            text-align: center;
            color: #335291;
            margin-top: 20px;
        }
    }

    .three-box-layout {

        margin-top: 30px;
        text-align: center;

        .three-box-content {
            text-align: left;
            margin-top: 10px;

            .bullete-points {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                span {
                    width: 16px;
                    height: 16px;
                    background-color: #335291;
                    border-radius: 50px;
                    display: inline-block;
                    position: relative;
                    margin-right: 15px;
                    top: -1px;

                    .arrow-point {
                        color: #fff;
                        font-size: 10px;
                        text-align: center;
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }

        img {
            width: 100%;
        }

        h6 {
            font-size: 24px;
            font-weight: 600;
            color: #000;
            margin-bottom: 0;
            text-transform: capitalize;
            width: 80%;
        }
    }
}

.show-more-list {
    background-color: transparent;
    border: 0;
    color: #335291;
    font-size: 20px;
    padding: 0;
    margin-top: 5px;
}

.hidden-lists {
    display: none;
}

.rotate-arrow {
    transform: rotate(180deg);
}






// ===================================END HERE==============================




// =====================================PARA SCROLL===============================

.para-wrap {
    padding: 100px 0px;

    .para-scroll {
        height: 400px;
        overflow: auto;
        background-image: url("../../public/images/home/para.webp");
        background-attachment: fixed;
        -moz-background-attachment: fixed;
        -o-background-attachment: fixed;
        -webkit-background-attachment: fixed;
        background-size: 100% 100%;
        -moz-background-size: 100% 100%;
        -o-background-size: 100% 100%;
        -webkit-background-size: 100% 100%;
        background-repeat: no-repeat;

    }

    .para-scroll:before {
        content: '';
        background-color: rgba(0, 0, 0, 0.12);
    }
}

.para-scroll::-webkit-scrollbar {
    width: 0;
}

.para-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.para-scroll::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}



// =======================================END HERE=================================



// =======================================SCALE BUSINESS============================

.scale-business {
    .sub-head {
        margin-bottom: 30px;
    }

    p {
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        color: #232830;
    }

    .start-driving-btn {
        border-radius: 40px;
        box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.16);
        background-color: #335291;
        font-size: 24px;
        font-weight: bold;
        color: #fff;
        border: 0;
        margin-top: 80px;
        padding: 20px 30px;
        transition: 0.5s all;
        margin-bottom: 20px;

        .glow-icon {
            display: inline-block;
            width: 40px;
            margin-right: 10px
        }
    }

    .start-driving-btn:hover {
        background-color: #344b78;
        transition: 0.5s all;
    }

    .scale-box {
        padding: 0px 100px;
        margin-top: 50px;

        .scale-box-wrap {
            background-color: #e2f7ff;
            padding: 30px 25px;
            display: flex;
            flex-direction: column;
            height: 100%;
            position: relative;

            .glow-icon {
                width: 125px;
                margin: 0px auto;

                svg {
                    position: relative;
                    bottom: 0;
                    z-index: 1;
                }
            }

            svg {
                display: inline-block;
                position: absolute;
                left: 0;
                bottom: -50px;
                z-index: -1;
            }

            h6 {
                font-size: 24px;
                font-weight: 600;
                text-align: center;
                color: #000;
                margin-top: 25px;
                text-transform: capitalize;
            }

            img {
                margin: 0px auto;
            }
        }
    }
}



// ======================================END HERE===================================


// =====================================SAAS===================================

.saas-wrap {
    padding: 100px 0 0;

    .inner-saas {
        padding-left: 70px;

        .sub-heading {
            text-align: left;
        }

        .sub-head {
            margin: 0;
        }

        p {
            font-size: 18px;
            font-weight: normal;
            text-align: left;
            color: #232830;
            margin-top: 20px;

        }
    }

    .wrap-content {
        width: 480px;
    }

    .blue-bg-wrap {
        background-color: #325191;
        background-image: url("../../public/images/home/bg-design.svg");
        background-size: cover;
        margin-top: 100px;
        padding-bottom: 80px;

        .row {
            justify-content: end;
        }

        .img-wrap-box {
            position: relative;
            top: -245px;
        }

        .laptop-img {
            position: absolute;
            left: -160px;
            top: 55%;
            transform: translateY(-50%);
        }
    }

    .marquee-text {
        position: relative;
        top: -100px;

        marquee {

            font-size: 96px;
            font-weight: 800;
            text-align: left;
            color: #fff;

            span {
                margin-right: 50px;
            }

            .outline-span {
                -webkit-text-stroke: 1px white;
                -webkit-text-fill-color: #325191;
            }
        }

    }
}




// =================================END HERE===================================



// =================================MAKE YOUR BUSINESS===============================

.make-your-business {
    padding: 150px 0 0;

    h2 {
        font-size: 42px;
        font-weight: bold;
        text-align: left;
        color: #000;
    }

    .box {
        border-radius: 10px;
        border: solid 1px #6ecef1;
        background-color: #fff;
        padding: 50px 25px;
        display: flex;
        flex-direction: column;
        height: 100%;

        .glow-icon {
            width: 60px;
            margin: 0px auto;
        }

        p {
            margin-bottom: 0;
            font-size: 26px;
            font-weight: normal;
            text-align: center;
            color: #000;
            margin-top: 20px;
        }

    }

    .cust-margin-top {
        margin-top: 30px;
    }

}

.m-text {
    padding-top: 150px;

    marquee {
        font-size: 96px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.81;
        letter-spacing: 1.92px;
        text-align: left;
        color: #000;

        span {
            margin-right: 50px;
        }

        .outline-span {
            -webkit-text-stroke: 2px black;
            -webkit-text-fill-color: #ffffff;
        }
    }

}

// ===================================END HERE================================



// ===================================WHY CHOOSE==============================

.why-freightgain {
    padding: 100px 0px 0px;

    .sub-heading {
        font-size: 66px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.18;
        letter-spacing: normal;
        text-align: left;
        color: #000;
    }

    .sub-head {
        margin: 0;
    }

    .freightgain-wrap {
        margin-top: 20px;
    }

    .left-business {
        p {
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            text-align: left;
            color: #232830;
        }
    }

    .box {
        border-radius: 10px;
        border: solid 1px #6ecef1;
        background-color: #fff;
        padding: 50px 25px;
        display: flex;
        flex-direction: column;
        height: 100%;

        .glow-icon {
            width: 60px;
            margin: 0px auto;
        }

        p {
            margin-bottom: 0;
            font-size: 26px;
            font-weight: normal;
            text-align: center;
            color: #000;
            margin-top: 20px;
        }
    }

    .cust-margin-top {
        margin-top: 30px;
    }

}


.left-business {
    padding-left: 70px;
}


// =================================================================================


// ================================WITH FREIGHTGAIN===============================

.with-freightgain-wrap {
    svg.semi-circle {
        position: absolute;
        bottom: -45px;
        z-index: 1;
    }

    .box-wrap {
        .operation-box {
            margin-top: 68px;


            .dashed-circle {
                position: absolute;
                bottom: -38px;
                left: 0;
                right: 0;
                margin: 0px auto;
                z-index: 2
            }

            .row {
                margin: 0;
            }

            .col-lg-3 {
                padding: 0;
            }

            .op-box {
                padding: 50px 25px 80px;
                display: flex;
                flex-direction: column;
                height: 100%;
                align-items: center;
                justify-content: center;
                position: relative;

                h4 {
                    font-size: 25px;
                    font-weight: 600;
                    text-align: center;
                    color: #fff;
                    margin: 0;
                    text-transform: capitalize;
                    line-height: 32px;
                }
            }

            // .op-box:before {
            //     content: '';
            //     width: 150px;
            //     height: 150px;
            //     background-color: #fff;
            //     border-radius: 100px;
            //     position: absolute;
            //     bottom: -86px;
            // }


            .dark-box {
                background-color: #335291;
            }

            .light-box {
                background-color: #6ecef1;
            }
        }
    }
}

.loader-circle {
    width: 42px;
    height: 42px;
    border: 3px solid #000;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    animation: rotation 1s linear infinite;
    background-color: #000;
    position: absolute;
    bottom: -18px;
    left: 0;
    right: 0;
    margin: 0px auto;
    z-index: 3;
}

.loader-circle:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-bottom-color: #fecb6b;
}


@-webkit-keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


// ========================================END HERE================================



// =====================================HELP BUSINESS==============================

.help-wrapper {
    padding: 100px 0px 0px;

    .sub-p {
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        color: #232830;
        margin-top: 30px;
    }

    .sub-head {
        margin-top: 20px;
    }

    .help-box {
        padding: 50px 70px 20px;
        margin-top: 100px;

        .pad-right-30 {
            padding-right: 40px;
        }

        .pad-left-30 {
            padding-right: 40px;
        }

        .inner-help {
            border-radius: 12px;
            background-color: #335291;
            padding: 85px 60px 30px;
            text-align: left;
            display: flex;
            flex-direction: column;
            height: 100%;
            position: relative;

            .img-top-help {
                width: 60%;
                margin: 0px auto;
                position: absolute;
                left: 0;
                right: 0;
                top: -90px;
            }

            .help-box-img {
                width: 100%;
            }

            h2 {
                font-size: 42px;
                font-weight: 600;
                text-align: left;
                color: #fff;
                margin-top: 50px;
                margin-bottom: 0;
            }

            ul {
                margin: 0;
                padding: 0;
                margin-top: 40px !important;

                li {

                    font-size: 22px;
                    font-weight: 300;
                    text-align: left;
                    color: #fff;
                    margin-bottom: 48px;
                    padding-left: 50px;
                    position: relative;

                }

                li:before {
                    content: '';
                    background: url("../../public/images/home/check-circle.svg");
                    width: 32px;
                    height: 32px;
                    position: absolute;
                    left: 0;
                    background-repeat: no-repeat;
                    background-size: contain;
                    top: 4px;
                }

            }
        }
    }
}

.colorfull-bg {
    background: url("../../public/images/home/multi-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 795px;
    margin-top: -310px;
    padding-top: 310px;
}


// =======================================END HERE=================================




// ========================================INTEGRATEERP============================

.integrate-wrap {
    .company-partners {
        margin-top: 30px;
        position: relative;

        .row {
            justify-content: center;
        }
    }

    .company-partners:before {
        content: "";
        left: 0rem;
        top: 50%;
        width: 90%;
        position: absolute;
        height: 10rem;
        border-left: 0.3rem solid rgb(255, 184, 64);
        border-right: 0.3rem solid rgb(255, 184, 64);
        border-radius: 6.5rem;
        animation: animate-before 1s 0.2s linear infinite;
        -webkit-animation: animate-before 1s 0.2s linear infinite;
        right: 0;
        margin: 0px auto;
        text-align: center;
        opacity: 0;
        transform: translateY(-50%)
    }

    .company-partners:after {
        content: "";
        left: 0;
        right: 0;
        margin: 0px auto;
        top: 50%;
        width: 95%;
        position: absolute;
        height: 13rem;
        border-left: 0.3rem solid rgb(255, 184, 64);
        border-right: 0.3rem solid rgb(255, 184, 64);
        border-radius: 8rem;
        opacity: 0;
        -webkit-animation: animate-after 1s 0.3s linear infinite;
        animation: animate-after 1s 0.3s linear infinite;
        transform: translateY(-50%);
    }

}

@-webkit-keyframes animate-after {
    0% {
        opacity: 0.1;
    }

    33% {
        opacity: 1;
        width: 96%;

    }

    66% {
        opacity: 0;
        width: 97%;

    }

    100% {
        opacity: 0;
        width: 98%;

    }
}

@keyframes animate-after {
    0% {
        opacity: 0.1;
    }

    33% {
        opacity: 1;
        width: 96%;

    }

    66% {
        opacity: 0;
        width: 97%;

    }

    100% {
        opacity: 0;
        width: 98%;

    }
}

@-webkit-keyframes animate-before {
    0% {
        opacity: 0.1;
    }

    33% {
        opacity: 1;
        width: 91%;

    }

    66% {
        opacity: 0;
        width: 92%;

    }

    100% {
        opacity: 0;
        width: 93%;

    }
}

@keyframes animate-before {
    0% {
        opacity: 0.1;
    }

    33% {
        opacity: 1;
        width: 91%;

    }

    66% {
        opacity: 0;
        width: 92%;

    }

    100% {
        opacity: 0;
        width: 93%;

    }
}

.wave-div {
    border-left: 0.3rem solid rgb(255, 184, 64);
    border-right: 0.3rem solid rgb(255, 184, 64);
    border-radius: 8rem;
    width: 100%;
    display: block;
    position: absolute;
    width: 85%;
    height: 6rem;
    left: 0;
    right: 0;
    margin: 0px auto;
    top: 50%;
    opacity: 0;
    -webkit-animation: animate-wave 1s 0.1s linear infinite;
    animation: animate-wave 1s 0.1s linear infinite;
    transform: translateY(-50%);
}

@-webkit-keyframes aanimate-wave {
    0% {
        opacity: 0.1;
    }

    33% {
        opacity: 1;
        width: 86%;

    }

    66% {
        opacity: 0;
        width: 87%;

    }

    100% {
        opacity: 0;
        width: 88%;

    }
}

@-webkit-keyframes aanimate-wave {
    0% {
        opacity: 0.1;
    }

    33% {
        opacity: 1;
        width: 86%;

    }

    66% {
        opacity: 0;
        width: 87%;

    }

    100% {
        opacity: 0;
        width: 88%;

    }
}

@keyframes animate-wave {
    0% {
        opacity: 0.1;
    }

    33% {
        opacity: 1;
        width: 86%;

    }

    66% {
        opacity: 0;
        width: 87%;

    }

    100% {
        opacity: 0;
        width: 88%;

    }
}

.btn-text {
    display: inline-block;
    vertical-align: middle;
}

// ==========================================================================



// ===================================MODAL POPUP===============================

.pop-up-full-box {
    .white-border-btn {
        border-radius: 40px;
        background-color: #335291;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        border: 0;
        padding: 10px 40px;
        transition: 0.5s all;
        margin-top: 30px;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .red-mandatory {
        color: red
    }

    textarea {
        height: auto !important;
        border-radius: 0px !important;
        resize: none;
        border-bottom: 1px solid #ddd;
    }

    .form-control {
        height: 50px;
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid #ddd;
        padding-left: 0;
    }

    .form-control:focus {
        border-bottom: 1px solid #335291;
    }

    .modal-body {
        padding: 40px 30px;
    }

    label {
        color: #282828;
        font-weight: 600;
        margin-bottom: 5px;
        font-size: 16px;
    }

    .modal-content {
        border-radius: 15px;
        border: 0
    }

    .modal-header {
        position: relative;
        background-color: #335291;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        h5 {
            margin-bottom: 0;
            font-size: 24px;
            font-weight: 700;
            color: #fff;
        }

        .cross-button {
            position: absolute;
            top: 48%;
            right: 15px;
            transform: translateY(-50%);
            z-index: 9;
            color: #fff;

            .btn {
                background-color: transparent;
                color: #ffffff;
                border: 0;
                padding: 0;
                font-size: 24px;
            }
        }
    }
}

.btn:focus {
    box-shadow: none;
}

.error-msg {
    color: red;
}

.cust-button-wrap {
    width: 200px !important;
    margin: 0px auto;
}

.cust-button span {
    font-size: 18px !important;
}

.cust-button-wrap .pb-button {
    padding: 0 !important;
    margin-top: 30px !important;
}

.cust-button-wrap .pb-container .pb-button span {
    font-weight: 700 !important;
    font-size: 18px;
}

.pb-container .pb-button:hover {
    background-color: #335291 !important;
    color: #fff;
    border: 2px solid #335291;
}

button:focus {
    outline: 0 !important;
    box-shadow: none !important;
}

.pop-up-full-box {
    top: 50%;
    transform: translateY(-50%) !important;
    margin-top: 0 !important;
}



// ===============================Privacy Policy=======================

.privacy-wrapper {
    text-align: left;

    .lottie-wrapper {
        margin-top: -57px;
    }

    .common-head-wrap {
        background-color: #335291;
        height: 300px;
        position: relative;
        clip-path: ellipse(100% 81% at 50% 0);

        h1 {
            position: absolute;
            text-align: center;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
            margin-bottom: 0;
            color: #fff;
            font-weight: 700;
            font-size: 60px;
        }
    }

    .mid-content-wrap {
        padding: 0px 0px 60px 0;
        margin: 0;

        .sub-heading {
            text-align: left;
        }
    }
}

.lottie-wrapper {
    .not-found-img {
        width: 40%;
        margin: 0px auto;
    }
}

.Back-to-home .glow-icon {
    display: inline-block;
    width: 40px;
    margin-right: 10px;
}

.Back-to-home {
    display: inline-block;
    margin-top: 0 !important;
}

.button-mtop {
    position: relative;
    top: -85px;
}

.mid-content-wrap h2 {
    font-size: 24px;
}


// ==========================================================================




// ===============================PRESENTING FG==============================

.presenting-wrapper {
    padding: 100px 0 0;

    .presenting-three {
        text-align: left;
        margin-top: 30px;
    }

    .div-box {
        .img-with-box {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            img {
                width: 38px;
                margin-right: 15px;
            }

            h2 {
                margin-bottom: 0;
                font-size: 36px;
                font-weight: bold;
                text-align: left;
                color: #000;
            }

        }

        p {
            font-size: 18px;
            font-weight: normal;
            text-align: left;
            color: #232830;
            margin-bottom: 0;
        }
    }

    .vision-mission-wrapper {
        border-radius: 12px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12);
        background-color: #e6f9ff;
        margin-top: 100px;
        padding: 60px 60px;
    }

    .vision-mission-wrapper {
        .row {
            display: flex;
            align-items: center;
        }

        .box-vision {
            text-align: left;

            h3 {
                font-size: 42px;
                font-weight: bold;
                text-align: left;
                color: #000;
                margin-bottom: 15px;
            }

            p {
                font-size: 26px;
                font-weight: normal;
                text-align: left;
                color: #000;
                margin-bottom: 0;
            }
        }
    }
}






// ===============================END HERE===============================


.operation-box {

    .slick-list {
        padding-bottom: 70px;
    }

    .slick-dots li button:before {
        opacity: 0.5;
        color: #335291;
    }

    .slick-dots li button {
        padding: 0;
    }

    .slick-prev:before {
        content: "";
        background-image: url("../../public/images/home/left-arrow-blue.png");
        width: 14px;
        height: 24px;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        opacity: 1;
    }

    .slick-next:before {
        content: "";
        background-image: url("../../public/images/home/right-arrow-blue.png");
        width: 14px;
        height: 24px;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        opacity: 1;
    }

    .slick-dots li.slick-active button:before {
        opacity: 1;
        background-color: #335291;
        border-radius: 50px;
        width: 20px;
        height: 7px;
        font-size: 0;
    }

    .slick-dots li button:before {
        width: 8px;
        height: 8px;
        font-size: 0;
        background-color: #335291;
        border-radius: 50px;
        left: 0;
        right: 0;
        margin: 0px auto;
    }

    .slick-prev:before,
    .slick-next:before {
        color: #335291;
        opacity: 1;
        font-size: 25px;
    }

    .slick-prev {
        left: -50px;
    }

    .slick-next {
        right: -40px;
    }

}



.shipping-proccess {
    .slick-prev:before {
        content: "";
        background-image: url("../../public/images/home/left-arrow-white.png");
        width: 14px;
        height: 24px;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        opacity: 1;

    }

    .slick-next:before {
        content: "";
        background-image: url("../../public/images/home/right-arrow-white.png");
        width: 14px;
        height: 24px;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        opacity: 1;

    }
}




//========================================================================



//=================================Expertise==============================


.expertise-wrap {
    padding: 100px 0px 0px;

    .sub-heading {
        text-align: left;
    }

    .orange-line-wrap {
        margin: 0;
    }

    .left-business {
        text-align: left;
    }

    p {
        font-size: 18px;
        font-weight: normal;
        text-align: left;
        color: #232830;
        margin-top: 20px;
    }

    .box-6 {
        text-align: left;

        span {
            background-color: #6ecef1;
            padding: 6px 15px;
            border-radius: 18px;
            font-weight: bold;
            color: #fff;
            margin-bottom: 15px;
            font-size: 18px;
            display: inline-block;
            text-align: center;
        }

        h4 {
            font-size: 42px;
            font-weight: bold;
            text-align: left;
            color: #000;
            margin-bottom: 0;
        }

        p {
            margin-top: 10px;
        }

    }

    .shipping-proccess {
        border-radius: 12px;
        border: solid 1px #707070;
        background-color: #335291;
        margin-top: 75px;
        padding: 70px 100px;

        h2 {
            font-size: 42px;
            font-weight: bold;
            text-align: center;
            color: #fff;
            margin-bottom: 0;
        }

        .five-box {
            margin-top: 60px;

            ul {
                display: flex;
                flex-wrap: wrap;

                li {
                    width: 19.2%;
                    display: inline-block;
                    margin-right: 14px;
                    vertical-align: top;
                }

                li:last-child {
                    margin-right: 0;
                }

            }

            .white-box {
                border-radius: 12px;
                background-color: #fff;
                padding: 40px 25px;
                display: flex;
                flex-direction: column;
                height: 100%;

                .glow-icon {
                    width: 75px;
                    margin: 0px auto;
                    margin-bottom: 20px;
                }

                p {
                    margin: 0 !important;
                    font-size: 20px;
                    font-weight: normal;
                    text-align: center;
                    color: #000;
                }

            }
        }
    }

}


// ===================================================================



// ================================= Benifits===========================

.benifits-wrap{
    padding: 100px 0px 0;
    text-align: left;

    h2{
        text-align: left;
    }
    
    .orange-line-wrap{
        margin: 0;
    }
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        padding-left: 40px;
        
        li{
            font-size: 22px;
            font-weight: normal;
            text-align: left;
            color: #000;
            text-align: left;
            margin-bottom: 48px;
            padding-left: 50px;
            position: relative;
        }
        li:before{
            
                content: '';
                background: url("../../public/images/home/check-blue-circle.svg");
                width: 32px;
                height: 32px;
                position: absolute;
                left: 0;
                background-repeat: no-repeat;
                background-size: contain;
                top: 4px;
            }
    }

    .beni-two{
        margin-top: 50px;
    }

    .left-beni-img{
        padding-right: 20px;
    }
}


// =================================END HERE==============================