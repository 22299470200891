@media (min-width: 1800px) {

    .benifits-wrap .left-beni-img{
        padding-right: 60px;
    }
    .benifits-wrap ul li:before{
        content: '';
        top: 0;
    }

    .container {
        max-width: 1640px !important;
    }

    .main-banner .banner-content {
        top: 215px;
    }

    .with-freightgain-wrap .box-wrap .operation-box .op-box {
        padding: 50px 25px 130px;
    }

    .help-wrapper .help-box .inner-help ul li {
        margin-bottom: 25px;
    }

    .with-freightgain-wrap svg.semi-circle {
        bottom: -68px;
    }

    .help-wrapper .help-box .inner-help h2 {
        font-size: 36px;
    }

    .saas-wrap .marquee-text {
        top: -100px;
    }

    .saas-wrap .blue-bg-wrap {
        padding-bottom: 150px;
    }

    .scale-business .scale-box .scale-box-wrap svg {
        bottom: -75px;
        height: 150px;
    }

    .scale-business .start-driving-btn {
        margin-top: 170px;
    }

    .footer-wrapper {
        padding: 50px 0px 50px;
    }

    .scale-business .scale-box .scale-box-wrap {
        padding: 50px 25px;
    }

    .help-wrapper .help-box .inner-help .img-top-help {
        top: -155px;
    }

    .help-wrapper .help-box {
        margin-top: 160px;
    }

    .help-wrapper .help-box .inner-help {
        padding: 120px 60px 30px;
    }
}

@media (max-width: 1799px) {

    .expertise-wrap .shipping-proccess .five-box ul li {
        width: 19%;
    }



    .container {
        max-width: 1500px !important;
    }

    .main-banner .banner-content {
        top: 190px;
    }

    .main-banner .banner-content h1 {
        font-size: 55px;
    }

    .main-banner .banner-content p {
        font-size: 18px;
    }

    .main-banner .banner-content .input-setup .get-email-start {
        font-size: 18px;
    }

    .main-banner .banner-content .input-setup .form-control {
        font-size: 18px;
    }

    .challenge-wrap .box-wrap h2 {
        font-size: 36px;
    }

    .challenge-wrap .box-wrap h4 {
        font-size: 36px;
    }

    .challenge-wrap .three-box-layout h6 {
        font-size: 20px;
    }

    .sub-heading {
        font-size: 50px;
    }

    .saas-wrap .blue-bg-wrap {
        padding-bottom: 120px;
    }

    .with-freightgain-wrap svg.semi-circle {
        bottom: -62px;
    }

    .with-freightgain-wrap .box-wrap .operation-box .op-box {
        padding: 50px 25px 100px;
    }

    .help-wrapper .help-box .inner-help h2 {
        font-size: 32px;
    }

    .help-wrapper .help-box .inner-help .img-top-help {
        top: -137px;
    }

    .help-wrapper .help-box {
        margin-top: 140px;
    }

    .help-wrapper .help-box .inner-help {
        padding: 115px 60px 30px;
    }

    .help-wrapper .help-box .inner-help ul li {
        margin-bottom: 25px;
        font-size: 20px;
    }

    .scale-business .scale-box .scale-box-wrap svg {
        height: 115px;
        bottom: -80px;
    }

    .scale-business .start-driving-btn {
        margin-top: 165px;
    }

    .faq-wrapper .faq-note h5 {
        font-size: 22px;
    }

    .faq-wrapper .faq-note .card .home_cardBody p {
        font-size: 18px;
    }

    .footer-wrapper .get-in-touch {
        font-size: 32px;
    }
}


@media (max-width: 1600px) {

    .expertise-wrap .box-6 h4 {
        font-size: 36px;
    }

    .expertise-wrap .shipping-proccess {
        padding: 70px 80px;
    }

    .presenting-wrapper .div-box .img-with-box h2 {
        font-size: 28px;
    }

    .presenting-wrapper .vision-mission-wrapper .box-vision h3 {
        font-size: 36px;
    }

    .expertise-wrap .shipping-proccess h2 {
        font-size: 36px;
    }

    .presenting-wrapper .vision-mission-wrapper .box-vision p {
        font-size: 20px;
    }

    .button-mtop {
        top: -55px;
    }

    .container {
        max-width: 1440px !important;
    }

    .privacy-wrapper .common-head-wrap h1 {
        font-size: 40px;
    }

    .sub-heading {
        font-size: 36px;
    }

    .saas-wrap .inner-saas p {
        font-size: 18px;
    }

    .main-banner .banner-content h1 {
        font-size: 50px;
    }

    .why-freightgain .sub-heading {
        font-size: 36px;
    }

    .why-freightgain .box p {
        font-size: 20px;
    }

    .make-your-business h2 {
        font-size: 36px;
    }

    .make-your-business .box p {
        font-size: 20px;
    }

    .help-wrapper .help-box .inner-help ul li {
        font-size: 18px;
    }

    .help-wrapper .help-box .inner-help h2 {
        font-size: 30px;
    }

    .with-freightgain-wrap .box-wrap .operation-box .op-box h4 {
        font-size: 24px;
    }

    .scale-business .scale-box .scale-box-wrap h6 {
        font-size: 24px;
    }

    .scale-business .start-driving-btn {
        font-size: 20px;
        padding: 15px 30px;
    }

    .footer-wrapper .get-in-touch {
        font-size: 28px;
    }

    .footer-wrapper ul.social-icon li {
        margin-right: 30px;
    }

    .faq-wrapper .faq-note h5 {
        font-size: 20px;
    }

    .with-freightgain-wrap svg.semi-circle {
        bottom: -51px;
    }
}

@media (max-width: 1500px) {

    .help-wrapper .help-box .inner-help .img-top-help {
        top: -115px;
    }

    .container {
        max-width: 1340px !important;
    }

    .main-banner .banner-content h1 {
        font-size: 45px;
    }

    .main-banner .banner-content .orange-line-wrap {
        margin-bottom: 20px;
    }

    .main-banner .banner-content p {
        font-size: 18px;
    }

    .footer-wrapper .get-in-touch {
        font-size: 30px;
    }

    .footer-wrapper .ft-wrapper h5 {
        margin-top: 5px;
    }

    .footer-wrapper .ft-wrapper h5 {
        margin-bottom: 20px;
    }

    .footer-wrapper .ft-wrapper li {
        font-size: 16px;
    }

    .footer-wrapper .ft-wrapper a {
        font-size: 16px;
    }

    .footer-wrapper .ft-wrapper .ul-left li a {
        font-size: 16px;
    }

    .footer-wrapper .ft-wrapper .ul-right li a {
        font-size: 16px;
    }

    .scroll-top {
        width: 45px;
        height: 45px;
        color: #fff;
        font-size: 20px;
    }

    .footer-wrapper .reach-us li {
        margin-bottom: 25px;
    }

    .footer-wrapper .ft-wrapper .ul-left li,
    .footer-wrapper .ft-wrapper .ul-right li {
        margin-bottom: 25px;
    }

    .main-banner .banner-content .input-setup .form-control {
        height: 60px;
        font-size: 18px;
    }

    .main-banner .banner-content .input-setup {
        height: 60px;
        width: 500px;
    }

    .main-banner .banner-content .input-setup .get-email-start {
        font-size: 18px;
        width: 180px;
    }

    .footer-wrapper .footer-menu .orange-line-wrap {
        width: 180px;
    }

    .faq-wrapper {
        padding: 70px 0px;
    }

    .faq-wrapper .faq-note h5 {
        margin-bottom: 0;
        font-size: 20px;
    }

    .sub-heading {
        font-size: 40px;
    }

    .sub-head {
        width: 200px;
    }

    .footer-wrapper ul.social-icon li {
        margin-right: 32px;
    }

    .custom-navbar .let-start {
        padding: 0px 20px;
    }

    .challenge-wrap .box-wrap h4 {
        font-size: 36px;
    }

    .challenge-wrap .three-box-layout h6 {
        font-size: 18px;
    }

    .faq-wrapper .faq-note .card .home_cardBody p {
        font-size: 16px;
    }

    .para-scroll {
        height: 300px;
    }

    .scale-business .scale-box .scale-box-wrap h6 {
        font-size: 20px;
    }

    .scale-business .start-driving-btn {
        padding: 10px 30px;
        font-size: 18px;
        margin-top: 130px;
    }

    .saas-wrap .inner-saas p {
        font-size: 18px;
    }

    .saas-wrap .mqrquee-text marquee span {
        margin-right: 35px;
    }

    .marquee-text {
        position: relative;
        top: -130px;
    }

    .make-your-business .left-business h2 {
        font-size: 36px;
    }

    .make-your-business .box p {
        font-size: 18px;
    }

    .why-freightgain .sub-heading {
        font-size: 45px;
    }

    .why-freightgain .box p {
        font-size: 18px;
    }

    .with-freightgain-wrap .box-wrap .operation-box {
        margin-top: 50px;
    }

    .with-freightgain-wrap .box-wrap .operation-box .op-box h4 {
        font-size: 20px;
        line-height: 24px;
    }

    .help-wrapper .help-box .inner-help h2 {
        font-size: 24px;
    }

    .help-wrapper .help-box .inner-help ul li {
        font-size: 18px;
        margin-bottom: 30px;
        padding-left: 45px;
    }

    .help-wrapper .help-box .inner-help ul li:before {
        width: 25px;
        height: 25px;
    }
}

@media (max-width: 1400px) {

    .expertise-wrap .box-6 h4 {
        font-size: 32px;
    }

    .expertise-wrap p br {
        display: none;
    }

    .expertise-wrap .shipping-proccess .five-box ul li {
        width: 18.9%;
    }

    .presenting-wrapper .div-box p br {
        display: none;
    }

    .presenting-wrapper .vision-mission-wrapper .box-vision h3 {
        font-size: 28px;
    }

    .privacy-wrapper .common-head-wrap h1 {
        font-size: 36px;
    }


    .container {
        max-width: 1240px !important;
    }

    .challenge-wrap .box-wrap h2 {
        font-size: 32px;
    }

    .challenge-wrap .box-wrap h4 {
        font-size: 32px;
    }

    .sub-heading {
        font-size: 36px;
    }

    .main-banner .banner-content h1 {
        font-size: 40px;
    }

    .help-wrapper .help-box .inner-help .img-top-help {
        top: -102px;
    }

    .help-wrapper .help-box {
        margin-top: 120px;
    }

    .help-wrapper .help-box .inner-help {
        padding: 100px 30px 30px;
    }

    .why-freightgain .sub-heading {
        font-size: 36px;
    }

    .make-your-business .left-business h2 {
        font-size: 32px;
    }

    .help-wrapper .help-box .inner-help ul {
        margin-top: 30px !important;
    }

}

@media (max-width: 1366px) {

    .benifits-wrap ul li {
        font-size: 18px;
        margin-bottom: 35px;
    }  

    .benifits-wrap ul li:before{
        content: '';
        top:-2px
    }

    .expertise-wrap .shipping-proccess .five-box .white-box p{
        font-size: 18px;
    }

    .expertise-wrap .shipping-proccess .five-box ul li {
        width: 18.8%;
    }

    .container {
        max-width: 1200px !important;
    }

    .with-freightgain-wrap svg.semi-circle {
        bottom: -45px;
    }

    .main-banner .banner-content {
        top: 180px;
    }

    .scale-business .scale-box .scale-box-wrap svg {
        bottom: -40px;
        height: auto;
    }

}


@media (max-width: 1240px) {

    .benifits-wrap .left-beni-img {
        padding-right: 0px;
    }

    .expertise-wrap .shipping-proccess h2 {
        font-size: 32px;
    }

    .operation-box .slick-next {
        right: -30px;
    }

    .operation-box .slick-prev {
        left: -40px;
    }

    .expertise-wrap .shipping-proccess .five-box .white-box p {
        font-size: 16px;
    }

    .expertise-wrap .shipping-proccess {
        padding: 70px 50px;
    }

    .expertise-wrap .shipping-proccess .five-box .white-box {
        padding: 40px 15px;
    }

    .button-mtop {
        top: -45px;
    }

    .privacy-wrapper .common-head-wrap h1 {
        font-size: 28px;
    }


    .container {
        max-width: 1100px !important;
    }

    .main-banner .banner-content h1 {
        font-size: 32px;
    }

    .main-banner .banner-content {
        top: 160px;
    }

    .challenge-wrap .box-wrap h2 {
        font-size: 28px;
    }

    .challenge-wrap .box-wrap h4 {
        font-size: 28px;
    }

    .sub-heading {
        font-size: 32px;
    }

    .challenge-wrap .box-wrap {
        padding: 50px 50px;
    }

    .saas-wrap .inner-saas {
        padding-left: 50px;
    }

    .saas-wrap .inner-saas p {
        font-size: 16px;
        padding-right: 95px;
    }

    .with-freightgain-wrap svg.semi-circle {
        bottom: -37px;
    }

    .challenge-wrap .box-wrap p {
        font-size: 16px;
    }

    .help-wrapper .sub-p {
        font-size: 16px;
    }

    .help-wrapper .help-box .inner-help .img-top-help {
        top: -90px;
    }

    .help-wrapper .help-box {
        margin-top: 95px;
        padding: 50px 50px 20px;
    }

    .help-wrapper .help-box .inner-help {
        padding: 75px 30px 30px;
    }

    .help-wrapper .help-box .inner-help ul li {
        font-size: 16px;
        margin-bottom: 20px;
        padding-left: 32px;

    }

    .help-wrapper .help-box .inner-help ul li:before {
        width: 20px;
        height: 20px;
    }

    .why-freightgain .sub-heading {
        font-size: 32px;
    }

    .why-freightgain .left-business p {
        font-size: 16px;
    }

    .why-freightgain .box {
        padding: 35px 25px;
    }

    .make-your-business .box {
        padding: 35px 25px;
    }

    .left-business {
        padding-left: 50px;
    }

    .scale-business .start-driving-btn {
        margin-top: 135px;
    }

    .scale-business .scale-box .scale-box-wrap h6 {
        font-size: 16px;
    }

    .scale-business .scale-box .scale-box-wrap {
        padding: 10px 25px;
    }

    .footer-wrapper .get-in-touch {
        font-size: 26px;
    }

    .faq-wrapper .faq-note h5 {
        margin-bottom: 0;
        font-size: 18px;
    }

    .expertise-wrap p {
        font-size: 16px;
    }

    .benifits-wrap ul li:before {
        content: '';
        top: -1px;
    }

    .benifits-wrap ul li {
        font-size: 18px;
        margin-bottom: 30px;
    }

}


@media (max-width: 1100px) {

    .benifits-wrap {
        padding: 60px 0px 0px;
    }
    
    .benifits-wrap ul li{
        font-size: 16px;
        padding-left: 35px;
    }

    .benifits-wrap ul li br{
        display: none;
    }
    .benifits-wrap ul li:before{
        width: 20px;
        height: 20px;
        top: 2px;
    }

    .expertise-wrap {
        padding: 60px 0px 0px;
    }

    .expertise-wrap .shipping-proccess {
        margin-top: 60px;
    }

    .with-freightgain-wrap svg.semi-circle {
        bottom: -50px;
    }

    .presenting-wrapper .vision-mission-wrapper {
        padding: 60px 50px;
    }

    .expertise-wrap .box-6 span {
        padding: 6px 10px;
        font-size: 16px;
        line-height: 20px;
    }

    .expertise-wrap .box-6 h4 {
        font-size: 28px;
    }

    .expertise-wrap .shipping-proccess .five-box ul li {
        width: 31.33%;
        margin-bottom: 15px;
    }

    .expertise-wrap .shipping-proccess .five-box ul {
        justify-content: center;
    }


    .lottie-wrapper .not-found-img {
        width: 80%;
    }

    .container {
        max-width: 960px !important;
    }

    .custom-navbar .navbar-brand img {
        width: 170px;
    }

    .main-banner .banner-content h1 {
        font-size: 30px;
    }

    .main-banner .banner-content .orange-line-wrap {
        width: 150px;
    }

    .main-banner .banner-content .orange-line-wrap img {
        width: 100%;
    }

    .main-banner .banner-content {
        top: 115px;
        width: 75%;
    }

    .main-banner .banner-content h1 br {
        display: none;
    }

    .challenge-wrap .box-wrap h4 {
        font-size: 24px;
    }

    .help-wrapper .help-box .inner-help .img-top-help {
        top: -80px;
    }

    .help-wrapper .help-box {
        margin-top: 85px;
    }

    .help-wrapper .help-box .inner-help {
        padding: 65px 30px 30px;
    }

    .with-freightgain-wrap .box-wrap .operation-box .op-box {
        padding: 50px 25px 90px;
    }

    .challenge-wrap .box-wrap h2 br {
        display: none;
    }

    .saas-wrap .marquee-text {
        position: relative;
        top: -110px;
    }

    .with-freightgain-wrap .box-wrap .operation-box .dashed-circle {
        bottom: -32px;
        width: 70px;
    }

    .why-freightgain .box p {
        font-size: 16px;
    }

    .make-your-business .box p {
        font-size: 16px;
    }

    .scale-business p {
        font-size: 16px;
    }

    .scale-business .scale-box .scale-box-wrap h6 {
        margin-top: 10px;
    }

    .scale-business .start-driving-btn {
        margin-top: 120px;
    }

    .scale-business .start-driving-btn {
        padding: 10px 30px;
        font-size: 16px;
    }

    .saas-wrap {
        padding: 60px 0 0;
    }

    .help-wrapper {
        padding: 60px 0px 0px;
    }

    .why-freightgain {
        padding: 60px 0px 0px;
    }

    .para-wrap {
        padding: 60px 0px;
    }

    .scale-business .start-driving-btn {
        margin-top: 100px;
    }

    .faq-wrapper {
        padding: 60px 0px;
    }

    .presenting-wrapper {
        padding: 60px 0 0;
    }
}


@media (max-width: 1000px) {

    .presenting-wrapper .vision-mission-wrapper .box-vision h3 {
        font-size: 24px;
    }

    .presenting-wrapper .div-box .img-with-box h2 {
        font-size: 24px;
    }

    .challenge-wrap .three-box-layout h6 {
        font-size: 16px;
    }

    .privacy-wrapper .common-head-wrap h1 {
        font-size: 24px;
    }


    .container {
        max-width: 900px !important;
    }

    .main-banner .banner-content {
        top: 125px;
    }

    .main-banner .banner-content .orange-line-wrap {
        margin-bottom: 10px;
    }

    .help-wrapper .help-box .inner-help .img-top-help {
        top: -71px;
    }

    .main-banner .banner-content .input-setup .get-email-start {
        font-size: 16px;
    }

    .saas-wrap .inner-saas p {
        padding-right: 120px;
    }

    .with-freightgain-wrap .box-wrap .operation-box .op-box {
        padding: 50px 15px 80px;
    }

    .challenge-wrap .box-wrap {
        padding: 50px 50px 50px;
    }

    .challenge-wrap .box-wrap h2 {
        font-size: 24px;
    }

    .help-wrapper .help-box .inner-help h2 {
        font-size: 20px;
    }

    .help-wrapper .help-box .inner-help ul {
        margin-top: 20px !important;
    }

    .integrate-wrap .company-partners {
        margin-bottom: 60px;
    }

    .main-banner .banner-content .subscribe-input {
        margin-top: 20px;
    }

    .main-banner .banner-content .input-setup {
        height: 50px;
    }

    .main-banner .banner-content .input-setup .form-control {
        height: 50px;
        font-size: 16px;
    }

    .main-banner .banner-content .input-setup .get-email-start {
        height: 80%;
    }

    .main-banner .banner-content .input-setup .get-email-start .glow-icon {
        width: 26px;
    }

    .make-your-business .box {
        padding: 35px 15px;
    }

    .why-freightgain .box {
        padding: 35px 15px;
    }

    .scale-business .scale-box .scale-box-wrap {
        padding: 10px 15px 0;
    }

    .scale-business .scale-box .scale-box-wrap svg {
        bottom: -32px;
    }

    .footer-wrapper .ft-wrapper h5 {
        font-size: 20px;
    }

    .footer-wrapper .ft-wrapper li br {
        display: none;
    }

    .footer-wrapper .reach-us li {
        margin-bottom: 20px;
    }

    .footer-wrapper .footer-logo-wrap {
        width: 190px;
    }

    .footer-wrapper .footer-img {
        width: 100%;
    }

    .footer-wrapper .get-in-touch {
        font-size: 22px;
    }

    .copywrite-wrap p {
        font-size: 13px;
    }

    .copywrite-wrap ul li a {
        font-size: 13px;
    }

    .footer-wrapper ul.social-icon li {
        margin-right: 25px;
    }

    .main-banner .banner-content .input-setup .form-control {
        font-size: 16px;
    }

    .with-freightgain-wrap .box-wrap .operation-box {
        margin-bottom: 40px;
    }

    .expertise-wrap .box-6 h4 {
        font-size: 24px;
    }

    .presenting-wrapper .vision-mission-wrapper .box-vision p {
        font-size: 18px;
    }

}


@media (max-width:992px) {

    .challenge-wrap .three-box-layout .three-box-content .bullete-points span{
        top: 1px;
    }

    .presenting-wrapper .vision-mission-wrapper {
        padding: 60px 40px;
    }

    .challenge-wrap .three-box-layout .three-box-content .bullete-points {
        align-items: normal;
    }

    .operation-box .slick-next {
            right: -23px;
    }

    .operation-box .slick-prev {
        left: -35px;
    }

    .with-freightgain-wrap svg.semi-circle {
        bottom: -42px;
    }

    .container {
        max-width: 850px !important;
    }

    .main-banner .banner-content {
        width: 90%;
    }

    .challenge-wrap .box-wrap {
        padding: 50px 40px 50px;
    }

    .saas-wrap .inner-saas {
        padding-left: 40px;
    }

    .saas-wrap .inner-saas p {
        padding-right: 150px;
    }

    .with-freightgain-wrap .box-wrap .operation-box .op-box h4 {
        font-size: 18px;
    }

    .help-wrapper .help-box {
        padding: 50px 40px 20px;
    }

    .help-wrapper .help-box .inner-help {
        padding: 60px 30px 30px;
    }

    .help-wrapper .help-box .inner-help .img-top-help {
        width: 65%;
        top: -75px;
    }

    .integrate-wrap .company-partners {
        margin-bottom: 60px;
        margin-top: 60px;
    }

    .with-freightgain-wrap .box-wrap .operation-box {
        margin-top: 30px;
        margin-bottom: 0;
    }

    .scale-business .scale-box {
        padding: 0px 10px;
    }

    .saas-wrap .blue-bg-wrap .img-wrap-box {
        position: relative;
        top: -225px;
    }

    .footer-wrapper .ft-wrapper h5 {
        font-size: 20px;
    }
}

@media (max-width:991px) {

    .para-scroll{
        display: none;
    }

    .pop-up-full-box .modal-body {
        padding: 40px 15px;
    }

    .container {
        max-width: 850px !important;
    }

    .presenting-wrapper .vision-mission-wrapper {
        padding: 60px 40px;
    }

    .saas-wrap .inner-saas {
        padding-left: 0px;
    }

    .with-freightgain-wrap svg.semi-circle {
        bottom: -42px;
    }

    .help-wrapper .help-box {
        padding: 50px 0px 20px;
    }

    .help-wrapper .help-box .inner-help .img-top-help {
        top: -85px;
    }

    .company-partners .col-lg-8 {
        padding: 0px 100px;
    }

    .integrate-wrap .company-partners {
        margin-bottom: 40px;
    }

    .left-business {
        padding-left: 0px;
    }

    .sub-heading {
        font-size: 28px;
    }

    .why-freightgain .sub-heading {
        font-size: 28px;
    }

    .make-your-business .left-business h2 {
        font-size: 28px;
    }

    .faq-wrapper .faq-note {
        width: 95%;
    }

    .footer-wrapper .get-in-touch {
        font-size: 20px;
    }

    .main-banner {
        height: 70vh;
    }

    .wrapper-banner-slider .slider-wrap {
        height: 70vh;
    }

    .wrapper-banner-slider .slider-wrap img {
        height: 100%;
    }

    .main-banner .banner-content {
        top: 185px;
    }

    .main-banner .banner-content h1 {
        font-size: 32px;
    }

    .expertise-wrap .shipping-proccess h2 {
        font-size: 28px;
    }

}

@media (max-width:900px) {

    .expertise-wrap .shipping-proccess .five-box ul li {
        width: 31.1%;
    }

    .container {
        max-width: 800px !important;
    }

    .para-wrap .para-scroll {
        background-position-x: center;
    }

    .help-wrapper .sub-p br {
        display: none;
    }

    .scale-business p br {
        display: none;
    }
}

@media (max-width:850px) {
    .expertise-wrap .shipping-proccess .five-box ul li {
        width: 31%;
    }

    .container {
        max-width: 750px !important;
    }

    .para-wrap .para-scroll {
        background-position-x: center;
    }

    .saas-wrap .blue-bg-wrap .laptop-img {
        left: -107px;
    }

    .help-wrapper .help-box .inner-help .img-top-help {
        top: -75px;
    }
}

@media (max-width:820px) {

    .with-freightgain-wrap .box-wrap .operation-box .dashed-circle {
        bottom: -25px;
        width: 58px;
    }

    .loader-circle {
        width: 35px;
        height: 35px;
        bottom: -13px;
    }

    .loader-circle:after {
        width: 42px;
        height: 42px;
    }
    .with-freightgain-wrap svg.semi-circle {
        bottom: -35px;
    }
}

@media (max-width:800px) {

    .benifits-wrap ul {
        padding-left: 25px;
    }

    .expertise-wrap .shipping-proccess .five-box ul li {
        width: 30.1%;
    }

    .expertise-wrap .shipping-proccess {
        padding: 70px 30px;
    }

    .challenge-wrap .box-wrap {
        padding: 50px 40px 35px;
    }

    .container {
        max-width: 700px !important;
    }

    .operation-box .slick-list {
        padding-bottom: 25px;
    }

    .main-banner .banner-content {
        top: 175px;
    }

    .with-freightgain-wrap svg.semi-circle {
        bottom: -15px;
    }

    .with-freightgain-wrap .box-wrap .operation-box .dashed-circle {
        bottom: 2px;
        width: 50px;
    }

    .loader-circle {
        width: 32px;
        height: 32px;
        bottom: 11px;
    }

    .loader-circle:after {
        content: "";
        width: 40px;
        height: 40px;
    }

    .help-wrapper .help-box .pad-right-30 {
        padding-right: 25px;
    }

    .help-wrapper .help-box .pad-left-30 {
        padding-right: 25px;
    }

    .help-wrapper .help-box .inner-help {
        padding: 60px 20px 30px;
    }

    .help-wrapper .help-box .inner-help ul li {
        padding-left: 30px;
    }

    .why-freightgain .box {
        padding: 35px 5px;
    }

    .challenge-wrap .box-wrap p {
        font-size: 16px;
    }

    .presenting-wrapper .div-box p {
        font-size: 16px;
    }

    .challenge-wrap .three-box-layout h6 {
        font-size: 16px;
    }

    .saas-wrap .inner-saas p {
        font-size: 16px;
    }

    .saas-wrap .inner-saas p {
        padding-right: 185px;
    }

    .with-freightgain-wrap .box-wrap .operation-box .op-box h4 {
        font-size: 16px;
        line-height: 20px;
    }

    .with-freightgain-wrap .box-wrap .operation-box {
        margin-bottom: 10px;
    }

    .help-wrapper .sub-p {
        font-size: 16px;
    }

    .help-wrapper .help-box .inner-help h2 {
        font-size: 18px;
    }

    .help-wrapper .help-box .inner-help ul li {
        font-size: 16px;
        margin-bottom: 15px;

    }

    .why-freightgain .left-business p {
        font-size: 16px;
    }

    .why-freightgain .box p {
        font-size: 16px;
    }

    .make-your-business .box p {
        font-size: 16px;
    }
}


@media (max-width:768px) {

    .challenge-wrap .three-box-layout .three-box-content .bullete-points span {
        top: 1px;
    }

    .presenting-wrapper .vision-mission-wrapper .box-vision p {
        font-size: 16px;
    }

    .presenting-wrapper .vision-mission-wrapper {
        padding: 60px 30px;
    }
}

@media (max-width:767px) {

    .benifits-wrap ul {
        padding-left: 0;
        margin-top: 30px !important;
    }

    .shipping-proccess .slick-prev {
        left: -30px;
    }

    .shipping-proccess .slick-next {
        right: -18px;
    }
    .scale-points p {
        position: relative;
        padding-left: 12px;
    }

    .scale-points p:before{
        content: '';
        width: 5px;
        height: 5px;
        background-color: #335291;
        top: 7px;
        position: absolute;
        left: 0;
        border-radius: 50px;

    }

    .challenge-wrap .three-box-layout .three-box-content .bullete-points {
        margin-bottom: 0;
    }

    .scale-points {
        text-align: left;
    }

    .shipping-proccess .slick-dots {
        display: none !important;
    }

    .expertise-wrap .shipping-proccess .five-box ul li {
        width: 100%;
        padding: 0px 5px;
        margin-bottom: 0;
        min-height: 280px;
    }

    .operation-box .slick-next {
        right: -20px;
    }

    .operation-box .slick-prev {
        left: -32px;
    }

    .presenting-wrapper .vision-mission-wrapper {
        padding: 40px 30px;
    }

    .challenge-wrap .three-box-layout h6 {
        text-align: left;
    }

}

@media (min-width: 576px) and (max-width: 767.98px) {

    .scale-business .getademo-btn button {
        width: 50%;
    }

    .ft-wrapper .getademo-btn button {
        width: 50%;
    }

    .wrapper-banner-slider .slider-wrap {
        height: 100vh;
    }

    .main-banner {
        height: 100vh;
    }

    .main-banner .banner-content {
        width: 100%;
    }

    .main-banner .banner-content h1 {
        font-size: 44px;
        text-align: center;
        line-height: 1.3;
    }

    .main-banner .banner-content {
        top: 115px;
    }

    .main-banner .banner-content .input-setup {
        width: 100%;
    }

    .main-banner .banner-content .input-setup .get-email-start {
        font-size: 14px;
    }

    .main-banner .banner-content .orange-line-wrap {
        width: 150px;
        margin: 0px auto;
    }

    .main-banner .banner-content .input-setup .get-email-start {
        width: 185px;
    }

    .main-banner .banner-content .input-setup .form-control {
        height: 62px;
    }

    .main-banner .banner-content .input-setup {
        height: 62px;
    }

    .main-banner .banner-content {
        top: 115px;
        height: calc(100vh - 115px);
        z-index: 10;
    }

    .main-banner .banner-content .subscribe-input {
        position: absolute;
        width: 100%;
        left: 0;
        padding: 0px 15px;
        bottom: 90px;

    }

    .main-banner .wrapper-banner-slider:before {
        height: 180px;
    }

    .challenge-wrap {
        margin-top: -30px;
    }

    .challenge-wrap .box-wrap {
        padding: 35px 33px 35px;
    }

    .challenge-wrap .box-wrap h2 {
        font-size: 28px;
        text-align: center;
    }

    .challenge-wrap .box-wrap p {
        font-size: 16px;
        text-align: center;
    }

    .challenge-wrap .box-wrap h4 {
        font-size: 34px;
        line-height: 1.15;
    }

    .challenge-wrap .three-box-layout img {
        width: 65%;
        margin-bottom: 20px;
    }

    .challenge-wrap .three-box-layout h6 {
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        color: #000;
        margin-top: 0;
    }

    .three-box-wrap hr {
        border-top: 1px solid #e5e5e5;
    }

    .saas-wrap {
        padding: 55px 0 0;
    }

    .help-wrapper {
        padding: 55px 0px 0px;
    }

    .saas-wrap .inner-saas .sub-heading {
        text-align: center;
        line-height: 1.43;
    }

    .saas-wrap .inner-saas .sub-heading br {
        display: none;
    }

    .saas-wrap .inner-saas .sub-head {
        margin: 0px auto;
    }

    .saas-wrap .wrap-content {
        width: 100%;
    }

    .saas-wrap .inner-saas p {
        padding-right: 0px;
        text-align: center;
        font-size: 16px;
    }

    .saas-wrap .blue-bg-wrap .img-wrap-box {
        position: relative;
        top: -280px;
    }

    .saas-wrap .blue-bg-wrap {
        margin-top: 310px;
    }

    .saas-wrap .blue-bg-wrap .laptop-img {
        left: 0;
        bottom: -275px;
        top: auto;
    }

    .with-freightgain-wrap svg.semi-circle {
        bottom: -5px;

    }

    .container-radius {
        border-radius: 10px;
    }

    .saas-wrap .marquee-text {
        position: relative;
        top: -75px;
    }

    .saas-wrap .blue-bg-wrap {
        padding-bottom: 230px;
    }

    .with-freightgain-wrap .challenge-wrap {
        margin-top: -160px;
    }

    .with-freightgain-wrap .box-wrap .operation-box .op-box {
        padding: 40px 15px 110px;
    }

    .with-freightgain-wrap .box-wrap .operation-box .col-lg-3 {
        margin-bottom: 40px;
    }

    .saas-wrap .marquee-text marquee {
        font-size: 70px;
    }

    .saas-wrap .marquee-text marquee span {
        margin-right: 40px;
    }

    .with-freightgain-wrap .box-wrap .operation-box .op-box h4 {
        font-size: 18px;
    }

    .mb-0-767 {
        margin-bottom: 0 !important;
    }

    .sub-heading br {
        display: none;
    }

    .sub-head {
        width: 180px;
    }

    .help-wrapper .sub-head {
        margin-top: 15px;
    }

    .help-wrapper .help-box {
        padding: 30px 0px 20px;
    }

    .help-wrapper .sub-p {
        margin-top: 20px;
        font-size: 16px;
    }

    .help-wrapper .help-box .pad-right-30 {
        padding-right: 15px;
    }

    .help-wrapper .help-box .pad-left-30 {
        padding-right: 15px;
    }

    .two-tabs-content .nav-tabs .nav-link {
        font-size: 16px;
        border-radius: 50px;
        padding: 12px 2px;
        color: #000;
        font-weight: bold;
    }

    .two-tabs-content .nav-tabs li {
        width: 50%;
    }

    .two-tabs-content .nav-tabs .nav-link.active {
        border: 0;
    }

    .two-tabs-content .nav-tabs {
        border-bottom: 0;
    }

    .two-tabs-content .nav-tabs {
        background-color: #e6f8ff;
        padding: 10px 10px;
        border-radius: 50px;
    }

    .two-tabs-content .nav-tabs .nav-link.active {
        border: 0;
        background-color: #335291;
        color: #fff;
    }

    .two-tabs-content {
        margin-top: 30px;
    }

    .help-wrapper .help-box {
        width: 60%;
        margin: 0px auto;
        margin-top: 130px;
    }

    .help-wrapper .help-box .inner-help .img-top-help {
        top: -100px;
    }

    .help-wrapper .getademo-btn button {
        width: 50%;
    }

    .custom-navbar .navbar-brand img {
        width: 204px;
    }

    .m-text marquee {
        font-size: 70px;
    }

    .getademo-btn button {
        background-color: #335291;
        width: 90%;
        border: 0;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        border-radius: 50px;
        padding: 6px 0px;
    }

    .getademo-btn .glow-icon {
        display: inline-block;
        width: 40px;
        margin-right: 5px;
    }

    .main-banner .banner-content .input-setup .form-control {
        padding-right: 145px;
    }

    .pop-up-full-box .modal-body {
        padding: 30px 20px;
    }

    .colorfull-bg {
        height: 725px;
    }

    .help-wrapper .help-box .inner-help ul li {
        font-size: 16px;
    }

    .getademo-btn {
        margin-top: 20px;
    }

    .integrate-wrap .challenge-wrap {
        margin-top: -55px;
    }

    .integrate-wrap .company-partners:after {
        display: none;
    }

    .integrate-wrap .company-partners:before {
        display: none;
    }

    .wave-div {
        display: none;
    }

    .make-your-business .box p {
        font-size: 16px;
    }

    .why-freightgain .box p {
        font-size: 16px;
    }

    .company-partners .col-lg-8 {
        padding: 0px 15px;
    }

    .integrate-wrap .company-partners {
        margin-top: 40px;
        margin-bottom: 10px;
    }

    .why-freightgain .sub-heading {
        font-size: 28px;
        text-align: center;
    }

    .why-freightgain .sub-head {
        margin: 0px auto;
    }

    .why-freightgain .left-business p {
        font-size: 16px;
        text-align: center;
        margin-bottom: 35px;
    }

    .why-freightgain .cust-margin-top {
        margin-top: 10px;
    }

    .cust-margin-top .col-6 {
        margin-bottom: 10px;
    }

    .make-your-business .left-business h2 {
        font-size: 24px;
        text-align: center;
        margin-bottom: 40px;
    }

    .make-your-business .cust-margin-top {
        margin-top: 8px;
    }

    .cust-margin-top .col-6 {
        margin-bottom: 8px;
    }

    .scale-business {
        margin-top: 50px;
    }

    .scale-business .scale-box {
        padding: 0px 0px;
        margin-bottom: 90px;
    }

    .scale-business .scale-box .scale-box-wrap .glow-icon {
        width: 100%;
        margin: 0px auto;
    }

    .scale-business .scale-box .scale-box-wrap {
        padding: 10px 0px 10px;
    }

    .scale-points p {
        text-align: left !important;
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        color: #000;
        margin-bottom: 10px;
    }

    .scale-points p:last-child {
        margin-bottom: 0;
    }

    .why-freightgain .box {
        padding: 35px 30px;
    }

    .scale-business .scale-box .scale-box-wrap {
        display: block;
        height: auto;
        width: 60%;
        margin: 0px auto;
    }

    .faq-wrapper .faq-note h5 {
        display: flex;
        font-size: 18px;
        padding-right: 25px;
    }

    .faq-wrapper .faq-note .card {
        padding: 23px 15px;
    }

    .faq-wrapper .faq-note .card .faq-bullet {
        margin-right: 12px;
        font-size: 18px;
        top: -1px;
    }

    .faq-wrapper .faq-note .card .home_cardBody {
        padding: 10px 10px 10px 30px;
    }

    .faq-wrapper .faq-note .card .acc-icon {
        top: 20px;
        right: 15px;
    }

    .faq-wrapper .faq-note .card .home_cardBody p {
        font-size: 16px;
    }

    .why-freightgain .getademo-btn button {
        width: 50%;
    }

    .footer-wrapper .footer-logo-wrap {
        width: 240px;
        text-align: center;
        margin-bottom: 25px;
        margin: 0px auto;
    }

    .social-wrap-footer {
        margin-top: 40px;
        text-align: center;
    }

    .footer-wrapper .ft-wrapper a {
        font-size: 16px;
    }

    .footer-wrapper ul.social-icon li {
        margin-right: 40px;
    }

    .ft-hr {
        border-top: 1px solid #6ecef1;
        margin: 25px 0px;
    }

    .footer-wrapper .get-in-touch {
        font-size: 28px;
    }

    .footer-wrapper .footer-menu .orange-line-wrap {
        width: 140px;
    }

    .footer-wrapper .getademo-btn {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .social-icon a {
        font-size: 22px !important;
    }

    .scroll-top {
        right: 20px;
    }

    .mobile-hide {
        display: none;
    }

    .mobile-show {
        display: block;
    }

    .main-banner .banner-content .input-setup .get-email-start .glow-icon {
        margin-right: 5px;
    }

    .with-freightgain-wrap .box-wrap .operation-box .dashed-circle {
        bottom: 6px;
        width: 60px;

    }

    .loader-circle {
        bottom: 20px;
    }

    .copywrite-wrap .left-copywrite {
        text-align: center !important;
        margin-bottom: 10px;
    }

    .copywrite-wrap .right-copywrite {
        text-align: center !important;
        margin-bottom: 10px;
    }

    .copywrite-wrap {
        background-color: #fff;
        padding: 15px 0px;
    }
}


@media (max-width: 670px) {

    .with-freightgain-wrap svg.semi-circle {
        bottom: 0px;
    }

}


@media (max-width: 650px) {

    .with-freightgain-wrap .box-wrap .operation-box .dashed-circle {
        bottom: -3px;
    }

    .loader-circle {
        bottom: 12px;
    }

    .container {
        max-width: 600px !important;
    }

}


@media (max-width: 600px) {

    .operation-box .slick-prev {
        left: -33px;
    }

    .operation-box .slick-next {
        right: -20px;
    }
    
    .container {
        max-width: 570px !important;
    }

    .with-freightgain-wrap svg.semi-circle {
        bottom: -25px;
    }

    .saas-wrap .blue-bg-wrap .laptop-img {
        bottom: -250px;
    }
}


@media (max-width: 576px) {

    .getademo-btn button {
        width: 50%;
    }

    .help-wrapper .help-box {
        width: 70%;
        margin: 0px auto;
        margin-top: 130px;
    }

    .copywrite-wrap .left-copywrite {
        text-align: center !important;
        margin-bottom: 5px;
    }

    .copywrite-wrap .right-copywrite {
        text-align: center !important;
    }

}

@media (max-width: 575px) {
    
    .benifits-wrap ul li {
        font-size: 16px;
        padding-left: 30px;
        margin-bottom: 25px;
    }

    .div-box {
        margin-bottom: 40px;
    }

    .presenting-wrapper .div-box .img-with-box {
        justify-content: center;
    }

    .presenting-wrapper .div-box p {
        font-size: 16px;
        text-align: center;
    }

    .presenting-wrapper .div-box .img-with-box {
        margin-bottom: 10px;
    }

    .mbottom-0 {
        margin-bottom: 0 !important;
    }

    .presenting-wrapper .vision-mission-wrapper {
        margin-top: 60px;
    }

    .middle-man-wrap {
        margin: 40px 0px;
    }

    .presenting-wrapper .vision-mission-wrapper {
        padding: 40px 20px;
    }

    .expertise-wrap .box-6 {
        text-align: center;
        margin-top: 20px;
    }

    .expertise-wrap .box-6 h4 {
        font-size: 32px;
        text-align: center;
    }

    .expertise-wrap .box-6 p {
        text-align: center;
        margin-bottom: 0;
    }

    .tech-pro {
        margin-top: 40px !important;
    }

    .expertise-wrap .shipping-proccess h2 br {
        display: none;
    }

}





@media (max-width: 510px) {

    .with-freightgain-wrap svg.semi-circle {
        bottom: -25px !important;
    }

    .help-wrapper .help-box .inner-help .img-top-help {
        top: -65px;
    }
}



//=============================MOBILE RESPONSIVE=========================


@media (min-width: 481px) and (max-width: 575.98px) {

    .presenting-wrapper .div-box .img-with-box img {
        width: 30px;
    }

    .presenting-wrapper .div-box .img-with-box h2 {
        font-size: 20px;
    }

    .presenting-wrapper .vision-mission-wrapper {
        margin-top: 60px;
    }

    .presenting-wrapper .div-box .img-with-box h2 {
        font-size: 20px;
    }

    .challenge-wrap .three-box-layout .three-box-content .bullete-points span {
        top: 2px;
    }

    .presenting-wrapper .div-box .img-with-box img {
        width: 30px;
    }

    .presenting-wrapper .div-box .img-with-box {
        margin-bottom: 15px;
    }

    .presenting-wrapper .div-box .img-with-box h2 {
        font-size: 20px;
    }

    .loader-circle {
        bottom: -3px;
    }

    .with-freightgain-wrap svg.semi-circle {
        bottom: -42px;
    }

    .wrapper-banner-slider .slider-wrap {
        height: 100vh;
    }

    .main-banner {
        height: 100vh;
    }

    .main-banner .banner-content {
        width: 100%;
    }

    .main-banner .banner-content h1 {
        font-size: 40px;
        text-align: center;
        line-height: 1.2;
    }

    .main-banner .banner-content {
        top: 115px;
    }

    .main-banner .banner-content .input-setup {
        width: 100%;
    }

    .main-banner .banner-content .input-setup .get-email-start {
        font-size: 14px;
    }

    .main-banner .banner-content .orange-line-wrap {
        width: 150px;
        margin: 0px auto;
    }

    .main-banner .banner-content .input-setup .get-email-start {
        width: 133px;
    }

    .main-banner .banner-content .input-setup .form-control {
        height: 62px;
    }

    .main-banner .banner-content .input-setup {
        height: 62px;
    }

    .main-banner .banner-content {
        top: 115px;
        height: calc(100vh - 115px);
        z-index: 10;
    }

    .main-banner .banner-content .subscribe-input {
        position: absolute;
        width: 100%;
        left: 0;
        padding: 0px 15px;
        bottom: 90px;
    }

    .challenge-wrap .three-box-layout .three-box-content .bullete-points span {
        top: 2px;
    }

    .main-banner .wrapper-banner-slider:before {
        height: 180px;
    }

    .challenge-wrap {
        margin-top: -30px;
    }

    .challenge-wrap .box-wrap {
        padding: 30px 35px 35px;
    }

    .challenge-wrap .box-wrap h2 {
        font-size: 24px;
        text-align: center;
    }

    .challenge-wrap .box-wrap p {
        font-size: 16px;
        text-align: center;
    }

    .challenge-wrap .box-wrap h4 {
        font-size: 34px;
        line-height: 1.15;
    }

    .challenge-wrap .three-box-layout img {
        width: 65%;
        margin-bottom: 20px;
    }

    .challenge-wrap .three-box-layout h6 {
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        color: #000;
    }

    .three-box-wrap hr {
        border-top: 1px solid #e5e5e5;
    }

    .saas-wrap {
        padding: 55px 0 0;
    }

    .help-wrapper {
        padding: 55px 0px 0px;
    }

    .saas-wrap .inner-saas .sub-heading {
        text-align: center;
        line-height: 1.43;
    }

    .saas-wrap .inner-saas .sub-heading br {
        display: none;
    }

    .saas-wrap .inner-saas .sub-head {
        margin: 0px auto;
    }

    .saas-wrap .wrap-content {
        width: 100%;
    }

    .saas-wrap .inner-saas p {
        padding-right: 0px;
        text-align: center;
    }

    .saas-wrap .blue-bg-wrap .img-wrap-box {
        position: relative;
        top: -280px;
    }

    .saas-wrap .blue-bg-wrap {
        margin-top: 310px;
    }

    .saas-wrap .blue-bg-wrap .laptop-img {
        left: 0;
        bottom: -225px;
        top: auto;
    }

    .container-radius {
        border-radius: 10px;
    }

    .saas-wrap .marquee-text {
        position: relative;
        top: -75px;
    }

    .saas-wrap .blue-bg-wrap {
        padding-bottom: 230px;
    }

    .with-freightgain-wrap .challenge-wrap {
        margin-top: -160px;
    }

    .with-freightgain-wrap .box-wrap .operation-box .op-box {
        padding: 25px 15px 80px;
    }

    .with-freightgain-wrap .box-wrap .operation-box .col-lg-3 {
        margin-bottom: 40px;
    }

    .saas-wrap .marquee-text marquee {
        font-size: 50px;
    }

    .saas-wrap .marquee-text marquee span {
        margin-right: 40px;
    }

    .with-freightgain-wrap .box-wrap .operation-box .op-box h4 {
        font-size: 16px;
    }

    .mb-0-767 {
        margin-bottom: 0 !important;
    }

    .sub-heading br {
        display: none;
    }

    .sub-head {
        width: 180px;
    }

    .help-wrapper .sub-head {
        margin-top: 15px;
    }

    .help-wrapper .help-box {
        padding: 30px 0px 20px;
    }

    .help-wrapper .sub-p {
        margin-top: 20px;
    }

    .help-wrapper .help-box .pad-right-30 {
        padding-right: 15px;
    }

    .help-wrapper .help-box .pad-left-30 {
        padding-right: 15px;
    }

    .two-tabs-content .nav-tabs .nav-link {
        font-size: 13px;
        border-radius: 50px;
        padding: 12px 2px;
        color: #000;
        font-weight: bold;
    }

    .two-tabs-content .nav-tabs li {
        width: 50%;
    }

    .two-tabs-content .nav-tabs .nav-link.active {
        border: 0;
    }

    .two-tabs-content .nav-tabs {
        border-bottom: 0;
    }

    .two-tabs-content .nav-tabs {
        background-color: #e6f8ff;
        padding: 10px 10px;
        border-radius: 50px;
    }

    .two-tabs-content .nav-tabs .nav-link.active {
        border: 0;
        background-color: #335291;
        color: #fff;
    }

    .two-tabs-content {
        margin-top: 30px;
    }

    .help-wrapper .help-box {
        margin-top: 70px;
    }

    .m-text marquee {
        font-size: 50px;
    }

    .getademo-btn button {
        background-color: #335291;
        width: 60%;
        border: 0;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        border-radius: 50px;
        padding: 6px 0px;
    }

    .getademo-btn .glow-icon {
        display: inline-block;
        width: 40px;
        margin-right: 5px;
    }

    .main-banner .banner-content .input-setup .form-control {
        padding-right: 145px;
    }

    .pop-up-full-box .modal-body {
        padding: 30px 20px;
    }

    .colorfull-bg {
        height: 690px;
    }

    .getademo-btn {
        margin-top: 20px;
    }

    .integrate-wrap .challenge-wrap {
        margin-top: -55px;
    }

    .integrate-wrap .company-partners:after {
        display: none;
    }

    .integrate-wrap .company-partners:before {
        display: none;
    }

    .wave-div {
        display: none;
    }

    .company-partners .col-lg-8 {
        padding: 0px 15px;
    }

    .integrate-wrap .company-partners {
        margin-top: 40px;
        margin-bottom: 10px;
    }

    .why-freightgain .sub-heading {
        font-size: 28px;
        text-align: center;
    }

    .why-freightgain .sub-head {
        margin: 0px auto;
    }

    .why-freightgain .left-business p {
        font-size: 14px;
        text-align: center;
        margin-bottom: 35px;
    }

    .why-freightgain .cust-margin-top {
        margin-top: 10px;
    }

    .cust-margin-top .col-6 {
        margin-bottom: 10px;
    }

    .make-your-business .left-business h2 {
        font-size: 24px;
        text-align: center;
        margin-bottom: 40px;
    }

    .make-your-business .cust-margin-top {
        margin-top: 8px;
    }

    .cust-margin-top .col-6 {
        margin-bottom: 8px;
    }

    .scale-business {
        margin-top: 50px;
    }

    .scale-business .scale-box {
        padding: 0px 0px;
        margin-bottom: 50px;
    }

    .scale-business .scale-box .scale-box-wrap .glow-icon {
        width: 100%;
        margin: 0px auto;
    }

    .scale-business .scale-box .scale-box-wrap {
        padding: 10px 0px 10px;
    }

    .scale-points p {
        text-align: left !important;
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        color: #000;
        margin-bottom: 10px;
    }

    .scale-points p:last-child {
        margin-bottom: 0;
    }

    .scale-business .scale-box .scale-box-wrap {
        display: block;
        height: auto;
    }

    .faq-wrapper .faq-note h5 {
        display: flex;
        font-size: 14px;
        padding-right: 20px;
    }

    .faq-wrapper .faq-note .card {
        padding: 23px 15px;
    }

    .faq-wrapper .faq-note .card .faq-bullet {
        margin-right: 12px;
        font-size: 18px;
        top: -1px;
    }

    .faq-wrapper .faq-note .card .home_cardBody {
        padding: 10px 30px;
    }

    .faq-wrapper .faq-note .card .acc-icon {
        top: 20px;
        right: 15px;
    }

    .faq-wrapper .faq-note .card .home_cardBody p {
        font-size: 14px;
    }

    .footer-wrapper .footer-logo-wrap {
        width: 240px;
        margin: 0px auto;
    }

    .social-wrap-footer {
        margin-top: 35px;
        text-align: center;
    }

    .footer-wrapper .ft-wrapper a {
        font-size: 16px;
    }

    .footer-wrapper ul.social-icon li {
        margin-right: 40px;
    }

    .ft-hr {
        border-top: 1px solid #6ecef1;
        margin: 25px 0px;
    }

    .footer-wrapper .get-in-touch {
        font-size: 28px;
    }

    .footer-wrapper .footer-menu .orange-line-wrap {
        width: 140px;
    }

    .footer-wrapper .getademo-btn {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .social-icon a {
        font-size: 22px !important;
    }

    .scroll-top {
        right: 20px;
    }

    .mobile-hide {
        display: none;
    }

    .mobile-show {
        display: block;
    }

    .main-banner .banner-content .input-setup .get-email-start .glow-icon {
        margin-right: 5px;
    }

    .with-freightgain-wrap .box-wrap .operation-box .dashed-circle {
        bottom: -17px;
        width: 60px;
    }
}

@media (max-width: 535px) {
    .with-freightgain-wrap svg.semi-circle {
        bottom: -33px;
    }
}


@media (max-width: 480px) {

    .shipping-proccess .slick-next:before{
        content: '';
        transform: scale(0.7);
    }

    .shipping-proccess .slick-prev:before{
        content: '';
        transform: scale(0.7);
    }

    .operation-box .slick-next:before{
        content: '';
        transform: scale(0.7);
    }

    .operation-box .slick-prev:before{
        content: '';
        transform: scale(0.7);
    }

    .operation-box .slick-prev {
        left: -30px;
    }

    .loader-circle {
        bottom: 0;
    }

    .operation-box .slick-next {
        right: -16px;
    }

    .privacy-wrapper .lottie-wrapper {
        margin-top: -45px;
    }

    .lottie-wrapper .not-found-img {
        width: 100%;
    }

    .button-mtop {
        top: 0;
    }

    .privacy-wrapper .common-head-wrap {
        height: 250px;
    }

    .privacy-wrapper .common-head-wrap h1 {
        top: 55%;
    }

    .faq-wrapper .faq-note {
        width: 100%;
    }

    .copywrite-wrap {
        padding: 10px 0px;
    }

    .help-wrapper .help-box .inner-help ul li:before {
        top: 1px;
    }

    .with-freightgain-wrap .box-wrap .operation-box .dashed-circle {
        bottom: -9px;
    }

    .wrapper-banner-slider .slider-wrap {
        height: 100vh;
    }

    .main-banner {
        height: 100vh;
    }

    .main-banner .banner-content {
        width: 100%;
    }

    .main-banner .banner-content h1 {
        font-size: 28px;
        text-align: center;
        line-height: 1.2;
    }

    .main-banner .banner-content {
        top: 115px;
    }

    .main-banner .banner-content .input-setup {
        width: 100%;
    }

    .main-banner .banner-content .input-setup .get-email-start {
        font-size: 14px;
    }

    .main-banner .banner-content .orange-line-wrap {
        width: 150px;
        margin: 0px auto;
    }

    .main-banner .banner-content .input-setup .get-email-start {
        width: 133px;
    }

    .main-banner .banner-content .input-setup .form-control {
        height: 62px;
    }

    .main-banner .banner-content .input-setup {
        height: 62px;
    }

    .main-banner .banner-content {
        top: 115px;
        height: calc(100vh - 115px);
        z-index: 10;
    }

    .main-banner .banner-content .subscribe-input {
        position: absolute;
        width: 100%;
        left: 0;
        padding: 0px 15px;
        bottom: 90px;

    }

    .main-banner .wrapper-banner-slider:before {
        height: 180px;
    }

    .challenge-wrap {
        margin-top: -30px;
    }

    .challenge-wrap .box-wrap {
        padding: 30px 25px 35px;
    }

    .challenge-wrap .box-wrap h2 {
        font-size: 24px;
        text-align: center;
    }

    .challenge-wrap .box-wrap p {
        font-size: 16px;
        text-align: center;
    }

    .challenge-wrap .box-wrap h4 {
        font-size: 34px;
        line-height: 1.15;
    }

    .challenge-wrap .three-box-layout img {
        width: 100%;
        margin-bottom: 20px;
    }

    .challenge-wrap .three-box-layout h6 {
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        color: #000;
    }

    .three-box-wrap hr {
        border-top: 1px solid #e5e5e5;
    }

    .saas-wrap {
        padding: 55px 0 0;
    }

    .help-wrapper {
        padding: 55px 0px 0px;
    }

    .saas-wrap .inner-saas .sub-heading {
        text-align: center;
        line-height: 1.43;
    }

    .saas-wrap .inner-saas .sub-heading br {
        display: none;
    }

    .saas-wrap .inner-saas .sub-head {
        margin: 0px auto;
    }

    .saas-wrap .wrap-content {
        width: 100%;
    }

    .saas-wrap .inner-saas p {
        padding-right: 0px;
        text-align: center;
    }

    .saas-wrap .blue-bg-wrap .img-wrap-box {
        position: relative;
        top: -280px;
    }

    .saas-wrap .blue-bg-wrap {
        margin-top: 310px;
    }

    .saas-wrap .blue-bg-wrap .laptop-img {
        left: 0;
        bottom: -175px;
        top: auto;
    }

    .container-radius {
        border-radius: 10px;
    }

    .saas-wrap .marquee-text {
        position: relative;
        top: -75px;
    }

    .saas-wrap .blue-bg-wrap {
        padding-bottom: 230px;
    }

    .with-freightgain-wrap .challenge-wrap {
        margin-top: -160px;
    }

    .with-freightgain-wrap .box-wrap .operation-box .op-box {
        padding: 25px 15px 80px;
    }

    .with-freightgain-wrap .box-wrap .operation-box .col-lg-3 {
        margin-bottom: 40px;
    }

    .saas-wrap .marquee-text marquee {
        font-size: 50px;
    }

    .saas-wrap .marquee-text marquee span {
        margin-right: 40px;
    }

    .with-freightgain-wrap .box-wrap .operation-box .op-box h4 {
        font-size: 16px;
    }

    .mb-0-767 {
        margin-bottom: 0 !important;
    }

    .sub-heading br {
        display: none;
    }

    .sub-head {
        width: 180px;
    }

    .help-wrapper .sub-head {
        margin-top: 15px;
    }

    .help-wrapper .help-box {
        padding: 30px 0px 20px;
    }

    .help-wrapper .sub-p {
        margin-top: 20px;
    }

    .help-wrapper .help-box .pad-right-30 {
        padding-right: 15px;
    }

    .help-wrapper .help-box .pad-left-30 {
        padding-right: 15px;
    }

    .two-tabs-content .nav-tabs .nav-link {
        font-size: 13px;
        border-radius: 50px;
        padding: 12px 2px;
        color: #000;
        font-weight: bold;
    }

    .two-tabs-content .nav-tabs li {
        width: 50%;
    }

    .two-tabs-content .nav-tabs .nav-link.active {
        border: 0;
    }

    .two-tabs-content .nav-tabs {
        border-bottom: 0;
    }

    .two-tabs-content .nav-tabs {
        background-color: #e6f8ff;
        padding: 10px 10px;
        border-radius: 50px;
    }

    .two-tabs-content .nav-tabs .nav-link.active {
        border: 0;
        background-color: #335291;
        color: #fff;
    }

    .two-tabs-content {
        margin-top: 30px;
    }

    .help-wrapper .help-box {
        margin-top: 120px;
    }

    .help-wrapper .help-box .inner-help .img-top-help {
        top: -95px;
    }

    .m-text marquee {
        font-size: 50px;
    }

    .getademo-btn button {
        background-color: #335291;
        width: 75%;
        border: 0;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        border-radius: 50px;
        padding: 6px 0px;
    }

    .getademo-btn .glow-icon {
        display: inline-block;
        width: 40px;
        margin-right: 5px;
    }

    .main-banner .banner-content .input-setup .form-control {
        padding-right: 145px;
    }

    .pop-up-full-box .modal-body {
        padding: 30px 20px;
    }

    .colorfull-bg {
        height: 690px;
    }

    .getademo-btn {
        margin-top: 30px;
    }

    .integrate-wrap .challenge-wrap {
        margin-top: -55px;
    }

    .integrate-wrap .company-partners:after {
        display: none;
    }

    .integrate-wrap .company-partners:before {
        display: none;
    }

    .wave-div {
        display: none;
    }

    .company-partners .col-lg-8 {
        padding: 0px 15px;
    }

    .integrate-wrap .company-partners {
        margin-top: 40px;
        margin-bottom: 10px;
    }

    .why-freightgain .sub-heading {
        font-size: 28px;
        text-align: center;
    }

    .why-freightgain .sub-head {
        margin: 0px auto;
    }

    .why-freightgain .left-business p {
        font-size: 14px;
        text-align: center;
        margin-bottom: 35px;
    }

    .why-freightgain .cust-margin-top {
        margin-top: 10px;
    }

    .cust-margin-top .col-6 {
        margin-bottom: 10px;
    }

    .make-your-business .left-business h2 {
        font-size: 24px;
        text-align: center;
        margin-bottom: 40px;
    }

    .make-your-business .cust-margin-top {
        margin-top: 8px;
    }

    .cust-margin-top .col-6 {
        margin-bottom: 8px;
    }

    .scale-business {
        margin-top: 50px;
    }

    .scale-business .scale-box {
        padding: 0px 0px;
        margin-bottom: 80px;
    }

    .scale-business .scale-box .scale-box-wrap .glow-icon {
        width: 100%;
        margin: 0px auto;
    }

    .scale-business .scale-box .scale-box-wrap {
        padding: 10px 0px 10px;
    }

    .scale-points p {
        text-align: left !important;
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        color: #000;
        margin-bottom: 10px;
        line-height: 20px;
    }

    .scale-points p:last-child {
        margin-bottom: 0;
    }

    .scale-business .scale-box .scale-box-wrap {
        display: block;
        height: auto;
    }

    .faq-wrapper .faq-note h5 {
        display: flex;
        font-size: 14px;
        padding-right: 20px;
    }

    .faq-wrapper .faq-note .card {
        padding: 23px 15px;
    }

    .faq-wrapper .faq-note .card .faq-bullet {
        margin-right: 12px;
        font-size: 18px;
        top: -1px;
    }

    .faq-wrapper .faq-note .card .home_cardBody {
        padding: 10px 0px 10px 30px;
    }

    .faq-wrapper .faq-note .card .acc-icon {
        top: 20px;
        right: 15px;
    }

    .faq-wrapper .faq-note .card .home_cardBody p {
        font-size: 14px;
    }

    .footer-wrapper .footer-logo-wrap {
        width: 240px;
        text-align: center;
        margin: 0px auto;
        margin-bottom: 25px;
    }

    .social-wrap-footer {
        margin-top: 20px;
        text-align: center;
    }


    .footer-wrapper .ft-wrapper a {
        font-size: 16px;
    }

    .footer-wrapper ul.social-icon li {
        margin-right: 30px;
    }

    .ft-hr {
        border-top: 1px solid #6ecef1;
        margin: 25px 0px;
    }

    .footer-wrapper .get-in-touch {
        font-size: 28px;
    }

    .footer-wrapper .footer-menu .orange-line-wrap {
        width: 140px;
        margin-bottom: 20px;
    }

    .footer-wrapper .getademo-btn {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .social-icon a {
        font-size: 22px !important;
    }

    .scroll-top {
        right: 20px;
        bottom: 70px;
    }

    .mobile-hide {
        display: none;
    }

    .mobile-show {
        display: block;
    }

    .main-banner .banner-content .input-setup .get-email-start .glow-icon {
        margin-right: 5px;
    }

}

@media (max-width: 465px) {
    .with-freightgain-wrap svg.semi-circle {
        bottom: -25px !important;
    }
}

@media (max-width: 440px) {

    .with-freightgain-wrap svg.semi-circle {
        bottom: -20px !important;
    }

    .help-wrapper .help-box {
        width: 100%;
    }
}

@media (max-width: 411px) {
    .with-freightgain-wrap svg.semi-circle {
        bottom: -15px !important;
    }
}

@media (max-width: 410px) {

    .with-freightgain-wrap svg.semi-circle {
       bottom: -15px !important;
    }

}

@media (max-width: 387px) {

    .with-freightgain-wrap svg.semi-circle {
        bottom: -10px !important;
    }

}

@media (max-width:382px) {
    
    .with-freightgain-wrap svg.semi-circle {
        bottom: -10px !important;
    }

    .saas-wrap .blue-bg-wrap .laptop-img {
        left: 0;
        bottom: -155px;
        top: auto;
    }

    .help-wrapper .help-box {
        padding: 30px 0px 20px;
        width: 100%;
    }

    .help-wrapper .help-box {
        margin-top: 100px;
    }

    .help-wrapper .help-box .inner-help .img-top-help {
        top: -82px;
    }

    .getademo-btn button {
        width: 90%;
    }
}

@media (max-width:370px) {

    .with-freightgain-wrap svg.semi-circle {
        bottom: -8px !important;
    }

    .saas-wrap .blue-bg-wrap .img-wrap-box {
        position: relative;
        top: -260px;
    }
}

@media (max-width:325px) {

    .main-banner .banner-content h1 {
        font-size: 26px;
    }

    .challenge-wrap .box-wrap h4 {
        font-size: 26px;
    }

    .saas-wrap .blue-bg-wrap {
        margin-top: 277px;
    }

    .saas-wrap .blue-bg-wrap .img-wrap-box {
        top: -220px;
    }

    .saas-wrap .blue-bg-wrap .laptop-img {
        bottom: -119px;
    }

    .with-freightgain-wrap svg.semi-circle {
        bottom: -8px !important;
    }

    .two-tabs-content .nav-tabs .nav-link {
        font-size: 11px;
        padding: 12px 0px;
    }

    .saas-wrap .marquee-text {
        top: -65px;
    }

    .help-wrapper .help-box {
        margin-top: 70px;
    }

    .help-wrapper .help-box .inner-help .img-top-help {
        top: -65px;
    }
}